<template>
  <news-list :noticeClass="noticeClass" />
</template>
<script>
import NewsList from "./news-list";
export default {
  name: "news-information",
  components: {
    NewsList,
  },
  data() {
    return {
      noticeClass: 1,
    };
  }
};
</script>

<style lang="scss" scoped>
</style>