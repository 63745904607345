<template>
  <div class="new-container"></div>
</template>
<script>
import { getNoticeList } from "@/api/index";
let pageNum = 1;
export default {
  name: "news-list",
  props: {
    noticeClass: Number,
  },
  data() {
    return {
      queryParams: {
        sign: "wx",
        pageNum,
        pageSize: 5,
        noticeClass: this.noticeClass,
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },

  mounted() {
    // pageNum = 1;
    // if (!self.refreshing) {
    //   this.onRefresh();
    // }
  },
  methods: {
    getNoticeLists() {
      const self = this;
      self.$set(self.queryParams, "pageNum", pageNum++);
      getNoticeList(self.queryParams).then((res) => {
        if (self.refreshing) {
          self.list = [];
          self.refreshing = false;
        }
        if (res.code == 200) {
          for (let i = 0; i < res.rows.length; i++) {
            self.list.push(res.rows[i]);
          }
          self.loading = false;
          if (self.list.length >= res.total) {
            self.finished = true;
          }
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      pageNum = 1;
      this.getNoticeLists();
    },
    goNewsDeatil(item) {
      this.$router.push(`/news-detail/${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
